import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { setUser } from "@state/auth/AuthEvents";
import { useTranslation } from "react-i18next";
import {
  buildSsoLoginUrl,
  requestGetUserInfo,
  requestLogin,
} from "@state/auth/AuthEffects";
import InputFormField from "@components/core/inputs/InputFormField";
import SubmitButton from "@components/core/buttons/SubmitButton";
import {
  EMAIL_URL_PARAM,
  OTP_URL_PARAM,
  formValidateTriggers,
  REGISTRATION_PARAM,
  REGISTRATION_PROFILE_PARAM,
} from "@utils/Constant";
import OtpMailModal from "@components/core/modals/OtpMailModal";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { ROUTES } from "@routes/Routes";
import { computeRedirectUrlFrom } from "@utils/helpers/query-helper";
import { useAuthentificationCheck } from "@/state/auth/Authentification";
import { AuthResultResponseDto } from "@state/auth/dto/response/auth.result.response.dto";
import { OtpMode } from "@utils/enums/otp.mode.enum";
import { apiHelper } from "@utils/helpers/api.helper";
import { useForm } from "antd/lib/form/Form";
import BasicButton from "@components/core/buttons/BasicButton";
import LoginLayout from "@components/core/layouts/LoginLayout";
import LinkButton from "@components/core/links/LinkButton";
import BasicDivider from "@components/core/divider/BasicDivider";
import parse from "html-react-parser";
import PasswordFormField from "@components/core/inputs/PasswordFormField";
import { ApiErrors } from "@utils/ApiErrors";
import {
  canSelfRegister,
  findUserProfile,
  UserProfile,
} from "@utils/enums/profile.enum";

interface LoginFormData {
  identifier: string;
  password: string;
}

const LoginScreen: FunctionComponent = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useAuthentificationCheck();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const queryParams = new URLSearchParams(location.search);
  const [userEmail, setUserEmail] = useState<string>(
    queryParams.get(EMAIL_URL_PARAM) ?? "",
  );
  const [displayOtpMailModal, setDisplayOtpMailModal] = useState<boolean>(
    queryParams.get(OTP_URL_PARAM) === "true",
  );

  const registration = queryParams.get(REGISTRATION_PARAM) === "true";

  const profile: UserProfile | undefined = findUserProfile(
    queryParams.get(REGISTRATION_PROFILE_PARAM),
  );

  const InitialValues = {
    identifier: userEmail,
    password: "",
  };

  const handleAuthentication = () => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        const redirectUrl = computeRedirectUrlFrom(location.search);
        navigate(redirectUrl);
      }
    });
  };

  const handleSubmit = (values: LoginFormData) => {
    setButtonLoading(true);

    requestLogin({
      dto: {
        identifier: values.identifier,
        password: values.password,
      },
    })
      .then((authResponse: ParsedResponse<AuthResultResponseDto>) => {
        if (authResponse.responseCode === 200 && authResponse.data) {
          handleAuthentication();
        } else if (authResponse.responseCode === 202) {
          if (authResponse.data?.twoFactorMode === OtpMode.OTP_MAIL) {
            const email: string = authResponse.data.connectedUser?.email ?? "";
            setUserEmail(email);

            setDisplayOtpMailModal(true);
          } else if (authResponse.data?.twoFactorMode === OtpMode.U_LINK) {
            toastSuccess(t("ulink.messages.email"));
          }
        } else if (authResponse.responseCode === 403) {
          toastError(t("login.messages.account-disabled"));
        } else {
          if (authResponse.errorCode) {
            if (
              [
                ApiErrors.LOGIN_UNAUTHORIZED.valueOf(),
                ApiErrors.LOGIN_FORBIDDEN.valueOf(),
              ].includes(authResponse.errorCode) &&
              authResponse.maxAttempts &&
              authResponse.attempts
            ) {
              apiHelper.showErrorFromApiErrorCode(authResponse.errorCode, {
                remainingAccessAttempts:
                  authResponse.maxAttempts - authResponse.attempts,
              });
            } else {
              apiHelper.showErrorFromApiErrorCode(authResponse.errorCode);
            }
          } else {
            toastError(t("login.messages.error"));
          }

          return false;
        }

        return true;
      })
      .catch(() => {
        toastError(t("login.messages.error"));
        return false;
      })
      .finally(() => {
        setButtonLoading(false);
        return true;
      });
  };

  const handleSsoLogin = () => {
    const redirectUrl = computeRedirectUrlFrom(location.search);
    window.location.href = buildSsoLoginUrl(redirectUrl);
  };

  const profileArray: UserProfile[] = profile ? [profile] : [];

  return (
    <LoginLayout title={t("login.welcome")}>
      <>
        {registration ? (
          <div className="my-32">
            <p className={"bg-light-beige pb-20 pr-20 pl-20 pt-20"}>
              {canSelfRegister(profileArray)
                ? parse(
                    t("registration.confirmationSent", { email: userEmail }),
                  )
                : parse(t("registration.awaitingValidation"))}
            </p>
          </div>
        ) : null}
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <InputFormField
            form={form}
            module="login.form"
            field="identifier"
            required
          />
          <PasswordFormField module="login.form" field="password" required />
          <div className="pt-8 d-flex flex-column align-items-stretch gap-24">
            <SubmitButton
              module="login.form"
              isSubmitting={buttonLoading}
              label="submit"
            />
            <BasicButton
              variant="primary-outlined"
              text={t("login.form.actions.sso")}
              onClick={handleSsoLogin}
            />
            <LinkButton
              to={ROUTES.auth.forgotPassword.generate()}
              variant="transparent"
              text={t("login.form.actions.forgot-password")}
              className="text-center"
            />
          </div>
          <div className="my-42 d-flex justify-content-center">
            <BasicDivider />
          </div>
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-16">
            <p className="flex-grow-1 m-0 text-primary text-large">
              {t("login.form.actions.registration-description")}
            </p>
            <LinkButton
              to={ROUTES.auth.registration.generate()}
              variant="primary-outlined"
              text={t("login.form.actions.registration")}
            />
          </div>
        </Form>
        <OtpMailModal
          userEmail={userEmail}
          visible={displayOtpMailModal}
          onCancel={() => {
            setDisplayOtpMailModal(false);
          }}
        />
      </>
    </LoginLayout>
  );
};

export default LoginScreen;
